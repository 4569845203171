import React from 'react';
import { graphql } from 'gatsby';
import Previewable from '../components/Previewable';
import Layout from '../components/Layout';
import * as AcfLayout from '../components/Acf';
import { ColourCharts } from '../components/ColourCharts'
import { ProfilesAssembly } from '../components/ProfilesAssembly'
import { FabricationTechniques } from '../components/FabricationTechniques'
import SEO from '../components/SEO';
import { decodeEntities, stripHtmlTags } from '../utils/helpers';

const AcfComponent = ({ location, componentName, item, pageContext }) => {
  // If component does not exist in acf folder, print error message
  const ComponentName = AcfLayout[componentName];
  const componentTitle = item.__typename ? item.__typename.replace('WordPressAcf_','') : 'No name';
  if (!ComponentName) {
        return (
          <section className="page missing"><div className="inner">The <strong>"{componentTitle}"</strong> component is missing. <span>&#128540;</span></div></section>
        )
  }else{
    return (
      <ComponentName
        location={location}
        pageContext={pageContext}
        {...item}
      />
    );
  }

};

const Page = ({ data, location, pageContext }) => {
  const { wordpressPost: page, site, wordpressWpSettings } = data;
  const { wordpressUrl } = wordpressWpSettings
  if (!page) return null;
  const { title, yoast, excerpt, acf = {} } = page;
  const { layout } = acf;
  const { title: siteTitle } = wordpressWpSettings;
  return (
    <Layout location={location} wordpressUrl={wordpressUrl}>
      <SEO
        title={`${
          yoast.metaTitle ?
          yoast.metaTitle :
          `${decodeEntities(title)} | ${decodeEntities(siteTitle)}`
        }`}
        desc={excerpt ? stripHtmlTags(excerpt) : null}
        yoast={yoast}
        location={location}
      />
      {layout && layout.map((item, index) => {
        if (!item.__typename) return null;
        const layoutComponentName = item.__typename.replace('WordPressAcf_','');
        return (
          <AcfComponent
            key={index}
            componentName={layoutComponentName}
            item={item}
            location={location}
            pageContext={pageContext}
          />
        );
      })}

      {location.pathname.includes('/colour-charts/') &&
      <section className="colour-charts">
        <div className="wrap">
          <div className="inner">
            <div className="title">
              <h3>Colours</h3>
            </div>
            <ColourCharts/>
          </div>
        </div>
      </section>
      }


      {location.pathname.includes('/joiners-assembly-accessories/') &&
      <section className="profiles-assembly">
        <div className="wrap">
          <div className="inner">
            <div className="title">
              <h3>JOINERS & ASSEMBLY ACCESSORIES</h3>
            </div>
            <ProfilesAssembly/>
          </div>
        </div>
      </section>
      }

      {location.pathname.includes('/fabrication-techniques/') &&
      <section className="profiles-assembly">
        <div className="wrap">
          <div className="inner">
            <div className="title">
              <h3>FABRICATION TECHNIQUES</h3>
            </div>
            <FabricationTechniques/>
          </div>
        </div>
      </section>
      }
    </Layout>
  )
}

export default Previewable(Page, 'page');
//export default (Page);

export const pageQuery = graphql`
  query PageById($id: String!) {
    wordpressWpSettings {
      title
      siteUrl
      wordpressUrl
    }
    wordpressPost: wordpressPage(id: { eq: $id }) {
      title
      content
      excerpt
      wordpress_id
      yoast {
        metaTitle: title
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
        opengraph_image {
          source_url
        }
        twitter_image {
          source_url
        }
      }
      acf {
        layout: layout_page {
          __typename
          ... HeaderQuery
          ... BlogFeedQuery
          ... StaffProfilesQuery
          ... LinkListQuery
          ... ContentQuery
          ... ProductFilterQuery
          ... MarketsQuery
          ... SliderQuery
          ... FeatureListQuery
          ... DatasheetsQuery
        }
      }
    }
  }
`
