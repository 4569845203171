import React, {Component} from 'react';
import { StaticQuery, graphql } from 'gatsby';
import GatsbyLink from '../GatsbyLink';
import { Image } from '../Image'
import RenderContent from '../RenderContent'
import { isClient } from '../../utils/helpers'
import './ProfilesAssembly.scss';

// const ThisItem = (props) => {
//   const {item} = props
//   return (
//     <div className="group" >
//       <h5 className="group-title">{item.group_title}</h5>
//       <div className="group-content">
//       {item.profile && item.profile.map((object, index) =>
//         <div className="item" key={index} onClick={(event) => this.toggleModal(event, colour)}>
//           <div className="image"><Image src={object.image}/></div>
//           <h5><strong>{object.title.split('-')[1]}</strong><div className="colour-code">{object.title.split('-')[0]}</div></h5>
//         </div>
//       )}
//       </div>
//     </div>
//   )
// }

class ThisItem extends Component {

  state = {
    modalContent: null
  };

  componentDidMount() {
    if (isClient) {
      document.addEventListener('keydown', this.handleEscKey, false);
    }
  }

  componentWillUnmount() {
    if (isClient) {
      document.removeEventListener('keydown', this.handleEscKey, false);
    }
  }

  handleEscKey = (event) => {
    const { toggleNav, active } = this.props;
    if (event.keyCode === 27 && active) toggleModal(event);
  }

  toggleModal = (event, object) => {
    event.preventDefault();
    this.setState({ modalContent: object });
    console.log(object);
  }

  render() {
    const { item } = this.props
    const { modalContent } = this.state
    return (
      <div className="group">
        {modalContent && (
          <div className="profile-modal">
            <div className="inner">
              <button className="close" onClick={(event) => this.toggleModal(event, null)} />
              <div className="image"><Image src={modalContent.image} /></div>
              <h4 className="colour-title">
                <strong>{modalContent.title.split('-')[0]}</strong>
                <div className="colour-code">{modalContent.title.split('-')[1]}</div>
              </h4>
            </div>
          </div>
        )}
        <h5 className="group-title">{item.group_title}</h5>
        <div className="group-content">
          {item.profile && item.profile.map((object, index) =>
            <div className="item" key={index} onClick={(event) => this.toggleModal(event, object)}>
              <div className="image"><Image src={object.image}/></div>
              <h5>
                <strong>{object.title.split('-')[0]}</strong>
                <div className="colour-code">{object.title.split('-')[1]}</div>
              </h5>
            </div>
          )}
        </div>
      </div>
    )
  }
}



const ProfilesAssemblyPass = (props) => {
  const {data} = props
  const { wordpressPage } = data
  const { acf } = wordpressPage
  return (
    <div className="resource-content">
      {acf?.profiles_download_link?.source_url && <GatsbyLink to={acf.profiles_download_link.source_url} target="_blank" className="action">Download Booklet</GatsbyLink>}
      <h4 className="group-title">Joiners</h4>
      {acf.profiles && acf.profiles.map((item, index) =>
        <ThisItem key={index} item={item} />
      )}

      <GatsbyLink to="#" target="_blank" className="action">Download Booklet</GatsbyLink>
      <h4 className="group-title">Assembly</h4>
      {acf.assembly && acf.assembly.map((item, index) =>
        <ThisItem key={index} item={item} />
      )}
      <h4 className="group-title">Tech Data</h4>
      <RenderContent content={acf.tech_data}/>
    </div>
  )
}

export const ProfilesAssembly = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          wordpressPage(slug: {eq: "joiners-assembly-accessories"}) {
            acf {
              profiles {
                group_title
                profile {
                  title
                  image {
                    localFile {
                      childImageSharp {
                        fixed(width: 480) {
                          ... GatsbyImageSharpFixed_noBase64
                        }
                      }
                    }
                  }
                }
              }
              profiles_download_link {
                source_url
              }
              assembly {
                group_title
                profile {
                  title
                  image {
                    localFile {
                      childImageSharp {
                        fixed(width: 480) {
                          ... GatsbyImageSharpFixed_noBase64
                        }
                      }
                    }
                  }
                }
              }
              assembly_download_link {
                source_url
              }
              tech_data
            }
          }
        }
      `}
      render={data => <ProfilesAssemblyPass {...props} data={data} />}
    />
  );
}
