import React, { Component } from 'react';
import './Slider.scss';
import { ImagePass } from '../../Image/ImagePass';
import GatsbyLink from '../../GatsbyLink'
import Carousel from '../../Carousel';
import { decodeEntities } from '../../../utils/helpers';

export const Slider = (props) => {
  const {
    title,
    wrap,
    showNavigation,
    styleImage,
    subNavigation,
    slides
  } = props
  const settings = {
    container: 'page-carousel',
    nav: showNavigation ? true : false,
    mouseDrag: true,
    items: 1,
    controls: showNavigation ? true : false,
    rewind: true,
    loop:false,
    autoplay: false
  };
  return (
    <section className={`page-slider${wrap ? ' wrap' : ' no-wrap'}`}>
      <div className={`wrap`}>
        <div className="intro">
          <div className="title">
            {title && <h3 className="section-title">{title}</h3>}
          </div>
        </div>
        <div className={`slider${subNavigation ? ' sub' : ' inner'}`}>
          <Carousel settings={settings}>
            {slides.map((slide, slide_count) => (
              <div key={slide_count} className={`slide${styleImage ? ' style' : '' }`}>
                <ImagePass src={slide.image} className="background" />
                <div className="content">
                  <h4 dangerouslySetInnerHTML={{__html: slide.title}} />
                  {slide.link && slide.link.url && <GatsbyLink className="action" to={slide.link.url}>View Products</GatsbyLink>}
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  )
}
