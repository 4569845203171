import React from 'react';
import loadable from '@loadable/component'
import RelatedProducts from '../../Related/RelatedProducts'
import RelatedProjects from '../../Related/RelatedProjects.js'
import RelatedPosts from '../../Related/RelatedPosts'

export const PostFeed = (props) => {
  if (props.contentType === 'project') {
    return <RelatedProjects {...props} />
  }
  if (props.contentType === 'post') {
    return <RelatedPosts {...props} />
  }
  if (props.contentType === 'instagram') {
    const Instagram = loadable(props => import(`../../Instagram`))
    return <Instagram {...props} />
  }
  if (props.contentType === 'product') {
    return <RelatedProducts {...props} />
  }
  return null
}
