import React from 'react';
import { SideNavigation } from '../../SideNavigation'
import './PageNavigation.scss'

export const PageNavigation = (props) => {
  const { pageContext } = props
  return (
    <div className="page-navigation">
      <div className="wrap">
        <SideNavigation pageContext={pageContext}/>
      </div>
    </div>
  )
}
