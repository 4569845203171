import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import RenderContent from '../../RenderContent'
import './ProductFilter.scss';
import GatsbyLink from '../../GatsbyLink'
import { decodeEntities } from '../../../utils/helpers';
class ProductFilterPass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resultProducts: null,
      lead: {}
    }
  }

  filterProducts() {
    const { market, application } = this.state.lead
    const { products } = this.props.data
    const filterProducts = products.nodes.filter(product => !product.slug.includes('gatsby') && product.product_market.includes(parseInt(market)) && product.product_application.includes(parseInt(application)))
    this.setState({resultProducts: filterProducts})
  }

  handleChange(e) {
     let lead = this.state.lead
     lead[e.target.name] = e.target.value
     this.setState({lead})

     if (lead.market && lead.application) {
       this.filterProducts()
     } else {
       this.setState({resultProducts: null})
     }
  }


  render() {
    const { title, content, data } = this.props
    const { markets, applications, products } = data
    const { resultProducts } = this.state
    return (
      <section className="product-filter">
        <div className="wrap">
          <div className="inner">
            <div className="intro">
              <div className="title">
                {title && <h3 className="section-title">{decodeEntities(title)}</h3>}
              </div>
              <div className="content">
                <RenderContent content={content}/>
              </div>
            </div>
            <div className="tool">
              <div className="lead-form">
                <form>
                  <div className="form-inner">
                    <div>
                      <label htmlFor="market">Select your market</label>
                      <select name="market" onChange={e => this.handleChange(e)}>
                        <option value="">Select a market</option>
                        {markets.nodes && markets.nodes.map((market, index) =>
                          <option key={index} value={market.wordpress_id}>{decodeEntities(market.name)}</option>
                        )}
                      </select>
                    </div>
                    <div>
                      <label htmlFor="application">Select your Application</label>
                      <select name="application" onChange={e => this.handleChange(e)}>
                        <option value="">Select an application</option>
                        {applications.nodes && applications.nodes.map((application, index) =>
                          <option key={index} value={application.wordpress_id}>{decodeEntities(application.name)}</option>
                        )}
                      </select>
                    </div>
                    <div>
                      {resultProducts && <label>({resultProducts.length}) Product Results</label>}
                      <div className="filter-results">
                        {resultProducts && resultProducts.length > 0 && resultProducts.map((product, index) =>
                          <div key={index}>{decodeEntities(product.title)}<GatsbyLink to={product.path}>View Product</GatsbyLink></div>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export const ProductFilter = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          markets: allWordpressWpProductMarket {
            nodes {
              name
              slug
              wordpress_id
            }
          }
          applications: allWordpressWpProductApplication {
            nodes {
              name
              slug
              wordpress_id
            }
          }
          products: allWordpressWpProducts {
            nodes {
              product_application
              product_market
              title
              slug
              path
            }
          }
        }
      `}
      render={data => <ProductFilterPass {...props} data={data} />}
    />
  );
}
