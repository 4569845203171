import React from "react";
import GatsbyLink from '../../GatsbyLink';
import "./datasheets.scss";
import { ProductImage } from "../../Products/ProductImage";

export const Datasheets = (props) => {

  const { datasheets, title = "", content = "" } = props;

  if (!datasheets) return null;

  return (
    <div className="datasheets">
      <div className="wrap">
        <div className="datasheets-content">
          {title.length > 0 && <h3>{title}</h3>}
          {content.length > 0 && <section dangerouslySetInnerHTML={{ __html: content }} />}
        </div>
        <div className="datasheets-table">
          {datasheets.map((sheet, index) => (
            <GatsbyLink to={sheet.productDatasheet.source_url} target="_blank" className="datasheets-item action" key={index}>
              <ProductImage above={sheet.above_honeycomb} below={sheet.below_honeycomb} />
              <span>{sheet.productName}</span>
            </GatsbyLink>
          ))}
        </div>
      </div>
    </div>
)
}; 