import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import './Locations.scss'
import LazyLoad from 'react-lazy-load';



const LocationsPass = (props) => {
  const {data} = props
  const { locations } = data.wordpressAcfOptions.options
  return (
    <section className="locations">
      <div className="wrap">
        <div className="inner">
          {locations && locations.map((location, index) =>
            <div key={index} className="location">
              {location.googleMap &&
              <div className="iframe-block">
                <LazyLoad>
                  <iframe src={location.googleMap} frameBorder="0" allowFullScreen />
                </LazyLoad>
                <div className="loading"/>
              </div>
              }

              <h4 dangerouslySetInnerHTML={{__html: location.locationName}} />

              <p dangerouslySetInnerHTML={{__html: location.addressDisplay}} />

              {location.phone && <p><a
                href={`tel:${location.phone.replace(/[^A-Z0-9]/ig, "")}`}
                className='control-call'
                aria-label="Call Now"
              >{location.phone}</a></p>}

              {location.email && <p><a href={location.email} className="email" target="_blank" dangerouslySetInnerHTML={{__html: location.email}}/></p>}
              {location.mapLink && <p><a href={location.mapLink.url} className="map-link" target={location.mapLink.target}>{location.mapLink.title}</a></p>}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export const Locations = (props) => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          wordpressAcfOptions {
            options {
              locations {
                googleMap
                mapLink {
                  title
                  url
                  target
                }
                locationName
                phone
                addressDisplay
                email
              }
            }
          }
        }
      `}
      render={data => <LocationsPass {...props} data={data} />}
    />
  );
}
