import React from 'react';
import { PageHeader } from '../../PageHeader'

export const Header = (props) => {
  const {
    header,
    subHeader,
    location
  } = props
  return <PageHeader headerTitle={header} headerSubTitle={subHeader} headerBackgroundImage={null} location={location} />
}
