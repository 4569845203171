import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import GatsbyLink from '../GatsbyLink';
import { Image } from '../Image'
import RenderContent from '../RenderContent'
import './Accreditations.scss';

const AccreditationsPass = (props) => {
  const { data, category, title } = props
  const { allWordpressWpProductAccreditation, allWordpressWpProducts } = data
  let newAccreditationArray = []
  let accreditations = allWordpressWpProductAccreditation.nodes
  let allProducts = allWordpressWpProducts.nodes

  let products = category ?
    allProducts.filter(post => !post.slug.includes('gatsby') && post.product_category.find(cat => cat.wordpress_id === parseInt(category))).filter(post => post.product_accreditation.length > 0) :
    allProducts.filter(post => !post.slug.includes('gatsby') && post.product_accreditation.length > 0)

  const catProdCount = (id) => {
    return products.filter(prod => prod.product_accreditation.includes(id)).length
  }

  accreditations.sort(function(a, b) {
    return catProdCount(a.wordpress_id) - catProdCount(b.wordpress_id)
  })

  products.sort(function(a, b){
    return b.product_accreditation.length - a.product_accreditation.length;
  });

  if (products.length <= 0) return null

  return (
    <div className="accreditations-list">
      <div className="wrap">
        {title && <h4 dangerouslySetInnerHTML={{__html: `${title} Accreditations`}} />}
        <div className="table-wrap">
          <table className="accreditations-list">
            <tbody>
            <tr>
              <th></th>
              {accreditations && accreditations.map((acc, index) =>
                <th key={index} className="accreditation-name">
                  {acc.acf && acc.acf.logo && <div className="logo"><Image src={acc.acf.logo}/></div>}
                  <div className="title">{acc.name}</div>
                </th>
              )}
            </tr>
            {products && products.map((product, index) =>
              <tr className="product" key={index}>
                <td className="product-title"><GatsbyLink to={product.path} dangerouslySetInnerHTML={{__html: title ? product.title.replace(`${title.toUpperCase()} `, '').replace(`${title} `, '') : product.title}}/></td>
                {accreditations && accreditations.map((acc, index) =>
                  <td className="accreditation-name" key={index}>
                    {product.product_accreditation.includes(acc.wordpress_id) ? <span className="yes"/> : '' }
                  </td>
                )}
              </tr>
            )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export const Accreditations = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWordpressWpProductAccreditation {
            nodes {
              wordpress_id
              acf {
                logo {
                  source_url
                }
              }
              name
            }
          }
          allWordpressWpProducts {
            nodes {
              path
              slug
              title
              product_accreditation
              product_category {
                wordpress_id
              }
            }
          }
        }
      `}
      render={data => <AccreditationsPass {...props} data={data} />}
    />
  );
}
