import React, { Component } from 'react'
import Accordion from '../../Accordion';
import RenderContent from '../../RenderContent'
import { Image } from '../../Image'
import GatsbyLink from '../../GatsbyLink'
import './StaffProfiles.scss';
import { isClient } from '../../../utils/helpers'

export const Popup = ({profile, toggleModal}) => {
  return (
    <div className="popup">
      <div className="inner">
        <div className="image">
          <div className="aspect">
            <Image src={profile.image}/>
          </div>
        </div>
        <div className="content">
          <button className="close-modal" onClick={e => toggleModal(e, null)}/>
          <h4 className="name">{profile.name}</h4>
          <p className="position">{profile.position}</p>
          {profile.region && <p className="region">{profile.region === 'usa' ? 'Ayres USA' : 'Ayres AUS' }</p>}
          <RenderContent content={profile.profile}/>
          {profile.linkedIn && <GatsbyLink className="linked-in" to={profile.linkedIn.url} />}
        </div>
      </div>
    </div>
  )
}

class ThisStaffProfiles extends Component {
  state = { activeProfile: null }

  componentDidMount() {
    if (isClient) {
      document.addEventListener('keydown', this.handleEscKey, false);
    }
  }

  componentWillUnmount() {
    if (isClient) {
      document.removeEventListener('keydown', this.handleEscKey, false);
    }
  }

  toggleModal = (e, profile) => {
    e.preventDefault();

    const { activeProfile } = this.state;
    if (activeProfile) return this.setState({ activeProfile: null });
    return this.setState({ activeProfile: profile });
  }

  handleEscKey = (event) => {
    const { toggleNav, active } = this.props;
    if (event.keyCode === 27 && active) toggleModal(event);
  }



  render() {
    const { staffProfiles, title, content, customID } = this.props
    const { activeProfile } = this.state
    return (
      <section className="staff-profiles">
        <div className="wrap">
          <div className="inner">
            <div className="intro">
              {title && <h3 className="section-title">{title}</h3>}
              {content && <RenderContent className="intro-text" content={content}/>}
            </div>

            {activeProfile && <Popup profile={activeProfile} toggleModal={this.toggleModal}/>}

            <div className="profiles">
            {staffProfiles && staffProfiles.map((profile, index) => (
              <div className="profile" key={index} >
                <div className={`inner${profile.profile ? ' link' : ''}`} onClick={profile.profile ? e => this.toggleModal(e, profile) : null}>

                  <div className="image">
                    <div className="aspect">
                      <Image src={profile.image}/>
                    </div>
                  </div>
                  <div className="content">
                    <h4 className="name">{profile.name}</h4>
                    <p className="position">{profile.position}</p>
                    {profile.region && <p className="region">{profile.region === 'usa' ? 'Ayres USA' : 'Ayres AUS' }</p>}
                    {profile.profile && <span className="action">View Profile</span>}
                  </div>

                </div>
              </div>
            ))}
            </div>

          </div>
        </div>
      </section>
    );
  }
};

export const StaffProfiles = (props) => {
  return (
    <ThisStaffProfiles {...props} />
  )
}
