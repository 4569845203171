import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import GatsbyLink from '../GatsbyLink';
import { Image } from '../Image'
import RenderContent from '../RenderContent'
import './FabricationTechniques.scss';
import LazyLoad from 'react-lazy-load';

const FabricationTechniquesPass = (props) => {
  const {data} = props
  const { wordpressPage } = data
  const { acf } = wordpressPage
  return (
    <div className="resource-content">
      {acf.technique && acf.technique.map((item, index) => (
        <div className="group" key={index}>
          <h4 className="group-title">{item.title}</h4>
          {item.video && (
            <div className="video-block">
              <LazyLoad>
                <div dangerouslySetInnerHTML={{__html: item.video}} />
              </LazyLoad>
              <div className="loading" />
            </div>
          )}
        </div>
      )
      )}
    </div>
  )
}

export const FabricationTechniques = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          wordpressPage(slug: {eq: "fabrication-techniques"}) {
            acf {
              technique {
                title
                video
              }
            }
          }
        }
      `}
      render={data => <FabricationTechniquesPass {...props} data={data} />}
    />
  );
}
