import React from 'react'
import GatsbyLink from '../../GatsbyLink';
import RenderContent from '../../RenderContent';
import { Image } from '../../Image';
import './PostListItem.scss'
import { decodeEntities } from '../../../utils/helpers';
import { dateI18n } from '@wordpress/date';

const ListItem = (props) => {
  const { siteMetadata, data, pathPrefix, dateFormat } = props;
  const { excerpt, title, slug, path, featured_media, date, categories } = data
  const dateFormatted = dateFormat && date && dateI18n(dateFormat,date)
  return (
    <div className="post-list-item">
      <div>
        <div className="image"><GatsbyLink to={path} label={title}><Image src={featured_media} className="background" /></GatsbyLink></div>
        <div className="inner">
          {categories && categories.map((cat, index) =>
            <span className="cat" key={index}>{cat.name}</span>
          )}
          <h4 className="title"><GatsbyLink to={path}>{decodeEntities(title)}</GatsbyLink></h4>
          {dateFormatted && <span className="date">{dateFormatted}</span>}
          {slug && <GatsbyLink to={path} label={title} className="post-link">Read More</GatsbyLink>}
        </div>
      </div>
    </div>
  )
}

export default ListItem
