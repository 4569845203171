import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import RenderContent from '../../RenderContent'
import GatsbyLink from '../../GatsbyLink'
import {Image} from '../../Image'
import './Markets.scss';

const MarketsPass = props => {
  const { title, data } = props
  const markets = data.allWordpressWpProductMarket.nodes || []
  return (
    <section className="markets">
      <div className="wrap">
        <div className="inner">
          <div className="title">
            {title && <h3 className="section-title">{title}</h3>}
          </div>
          <div className="content">
            {markets && markets.map((market, index) =>
              <div className="market" key={index}>
                {market.acf && market.acf.icon && <div className="image"><Image src={market.acf.icon} lazy={false}/></div>}
                <h4 dangerouslySetInnerHTML={{__html: market.name}} />
                <RenderContent content={market.description}/>
                <GatsbyLink className="action" to={market.path}>Read More</GatsbyLink>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};


export const Markets = (props) => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          allWordpressWpProductMarket {
            nodes {
              acf {
                icon {
                  localFile {
                    publicURL
                  }
                }
              }
              path
              description
              name
            }
          }
        }
      `}
      render={data => <MarketsPass {...props} data={data} />}
    />
  );
}
